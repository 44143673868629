/* Set the default font for the body */
body {
  margin: 0;
  font-family: 'Futura', sans-serif !important; /* Futura for body and other texts */
  background-color: #f5f5f5; /* Lighter background */
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Applying Playfair Display to headers */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif !important; /* Playfair Display for headers */
  font-weight: 600; /* 600 is semi-bold, adjust as needed */
}

/* Ensure buttons, input fields, select, and textarea use Futura */
button, input, select, textarea {
  font-family: 'Futura', sans-serif !important;
}

/* Reusable classes for maroon accents */
.text-maroon {
  color: #000000;
}

.bg-maroon {
  background-color: #000000;
}

.border-maroon {
  border-color: #000000;
}

/* Importing Playfair Display and Futura fonts */
@font-face {
  font-family: 'Playfair Display';
  src: url('./fonts/playfair-display.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/futura.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}