/* App.css */

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-grow-1 {
  flex-grow: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Adjust the navbar height */
.navbar {
  padding: 1rem 1rem; /* Increase the top and bottom padding */
  background-color: transparent; /* Or set to the color of your page background */
  border-bottom: 1px solid #ccc; /* Optional: add a border to blend with the page */
}

/* Make navbar links smaller, centered, and with more padding */
.navbar .nav-link {
  font-size: 0.9rem; /* Smaller font size */
  padding: 0.75rem 1rem; /* Adjust padding as needed */
  text-align: center; /* Center text - this works if the navbar collapse is not active */
}

/* Optional: Center the brand/logo if needed */
.navbar-brand {
  justify-content: center;
  align-items: center;
  display: flex;
}

/* Adjust Navbar Toggler for smaller screens */
.navbar-toggler {
  padding: 0.25rem 0.5rem; /* Smaller toggler */
}

/* Additional styles for the dropdown to match the smaller text and padding */
.nav-dropdown .dropdown-menu {
  font-size: 0.9rem; /* Match the dropdown text size to the navbar links */
}

.custom-dropdown-item {
  color: black !important;
}

.custom-dropdown-item:hover {
  color: black !important;
  background-color: #f8f9fa !important; /* Optional: change background color on hover */
}
