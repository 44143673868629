/* Use the same maroon color for links, borders, and active elements */
:root {
    --maroon: #000000;
    --hover-background: #fff;
  }
  
  /* General styling for the account page */
  .account-container {
    padding: 30px 0;
  }
  
  .account-nav-col {
    padding-right: 30px;
  }
  
  .account-nav .nav-link {
    color: var(--maroon);
    background-color: transparent;
    border-radius: 0;
    border-left: 4px solid transparent;
    padding: 10px 15px;
    margin-bottom: 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .account-nav .nav-link:hover {
    background-color: #f8f8f8;
  }
  
  .account-nav .nav-link.active {
    color: #fff;
    background-color: var(--maroon);
    border-left: 4px solid var(--maroon);
  }
  
  .account-main-col {
    padding-left: 30px;
  }
  
  .info-card {
    border: 1px solid var(--maroon);
    background-color: #fff;
    margin-bottom: 20px;
  }
  
  .info-card-header {
    background-color: #eaeaea;
    color: #333;
    font-size: 1.1rem;
    border-bottom: none;
  }
  
  .info-item {
    border-bottom: 1px solid #eaeaea;
    padding: 12px 20px;
  }
  
  .info-card-footer {
    background-color: #fff;
    border-top: 1px solid #eaeaea;
    padding: 10px 20px;
  }
  
  .info-link {
    color: var(--maroon);
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .info-link:hover {
    color: var(--hover-background);
  }
  
  .info-button {
    background-color: var(--maroon);
    color: #fff;
    border: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .info-button:hover {
    background-color: var(--hover-background);
    color: var(--maroon);
  }
  
  /* Styling for the points section */
  .account-points-col {
    padding-left: 30px;
  }
  
  .points-section {
    border: 2px solid var(--maroon);
    border-radius: 0;
    padding: 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .points-title {
    color: var(--maroon);
    margin-bottom: 5px;
  }
  
  .points-value {
    font-size: 2.5rem;
    color: var(--maroon);
    margin-bottom: 10px;
  }
  
  .points-info {
    color: #666;
    font-size: 0.95rem;
  }

  /* Custom card styles */
.custom-card {
    background-color: #fff;
    border: 1px solid var(--maroon);
    margin-bottom: 20px;
    border-radius: 0; /* Hard edges */
  }
  
  .custom-card-header,
  .custom-card-footer {
    background-color: #f8f8f8; /* Slightly grey */
    color: var(--maroon);
    padding: 12px 20px;
    border: none; /* Removing default border */
  }
  
  .custom-card-body {
    padding: 20px;
  }
  
  /* List Group Item styles */
  .custom-list-group-item {
    border-bottom: 1px solid #eaeaea; /* Light border for separation */
    padding: 12px 20px; /* Padding for content */
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  /* ... Rest of the styles ... */
  
  /* Button styles */
  .custom-button {
    background-color: var(--maroon);
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .custom-button:hover {
    background-color: #fff;
    color: var(--maroon);
  }
  
  /* Active Nav Link styles */
  .account-nav .nav-link.active {
    background-color: var(--maroon);
    color: #fff;
    font-weight: bold;
  }
  
  /* Styling for the points section */
  .points-section {
    border: 2px solid var(--maroon);
    border-radius: 0; /* Hard edges */
    padding: 20px;
    background-color: #fff;
    text-align: center;
    margin-top: 20px;
  }
  
  