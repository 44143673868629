.cart-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.cart-items-container {
    flex: 3;
    padding: 20px;
    margin-right: 20px; /* Spacing between items and summary */
}

.cart-item {
    background-color: #fff;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 8px;
    border-left: 5px solid darkmaroon; /* Custom color */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-info h5, .item-info p {
    margin: 5px 0;
}

.checkout-summary {
    flex: 1;
    background-color: #600020; /* Dark maroon */
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2);
}

.checkout-btn, .remove-btn {
    background-color: #400015;
    color: #fff;
    border: none;
    margin-top: 10px; /* For checkout button */
}

.checkout-btn:hover, .remove-btn:hover {
    background-color: #300010;
}

.cart-title {
    color: #600020; /* Echoing the dark maroon theme */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .cart-container {
        flex-direction: column;
    }
    
    .checkout-summary, .cart-items-container {
        margin-right: 0;
        margin-bottom: 20px;
    }
}
