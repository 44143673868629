.custom-table-scroll {
    max-height: 400px; /* Optionally adjust height */
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ddd; /* Light border */
}

.custom-table thead th {
    position: sticky;
    top: 0;
    background-color: #000000;
    color: white;
    z-index: 10;
}

.custom-table {
    width: 100%;
    min-width: 800px; /* Adjust based on your content */
    margin-bottom: 0; /* Remove default margin */
    border-collapse: separate;
    border-spacing: 0;
}

.custom-table tbody td {
    padding: .5rem;
    text-align: center;
    vertical-align: middle;
    border-right: none;
}

.custom-table tbody tr:hover {
    background-color: #e8f0fe;
}

.custom-table tbody tr:nth-of-type(even) {
    background-color: #f8f9fa;
}

.custom-table .form-control,
.custom-table .form-check-input {
    margin: auto;
    display: block;
    width: 100%; /* Use full width to align with cell contents */
}

/* Additional styling */
.custom-table thead th {
    font-size: 0.9rem; /* Smaller font size for headers */
}

.custom-table td {
    font-size: 0.8rem; /* Smaller font size for table cells */
    border-top: none; /* Remove top border */
    padding: 0.3rem; /* More padding */
}

.custom-table th, .custom-table td {
    border-right: none; /* Remove right borders */
    border-left: none; /* Remove left borders */
}

.custom-table th:last-child, .custom-table td:last-child {
    border-right: 1px solid #dee2e6; /* Add right border to last cell */
}

.custom-table th.sticky-header {
    box-shadow: 0 2px 2px -1px rgba(0,0,0,0.4); /* Shadow for sticky header */
}

.shapes-slider-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px 0;
}

.shapes-slider .img-thumbnail {
    display: inline-block;
    margin-right: 10px;
    transition: transform 0.3s ease;
}

.shapes-slider .img-thumbnail:hover {
    transform: scale(1.1);
}

.slider {
    margin-bottom: 20px;
}

.track {
    height: 6px;
    background: #ddd;
    border-radius: 3px;
}

.thumb {
    height: 20px;
    width: 20px;
    background-color: #999;
    border-radius: 50%;
    cursor: grab;
}.shapes-slider-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px 0;
}

.shapes-slider .img-thumbnail {
    display: inline-block;
    margin-right: 10px;
    transition: transform 0.3s ease;
}

.shapes-slider .img-thumbnail:hover {
    transform: scale(1.1);
}

.slider {
    margin-bottom: 20px;
}

.track {
    height: 6px;
    background: #ddd;
    border-radius: 3px;
}

.thumb {
    height: 20px;
    width: 20px;
    background-color: #999;
    border-radius: 50%;
    cursor: grab;
}



.slider-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Add space between sliders */
  }
  
  .slider-title {
    display: flex;
    align-items: center;
    font-size: 14px; /* Smaller font size for titles */
    margin-bottom: 5px; /* Reduce space below the title */
  }
  
  .slider-with-input {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between elements */
  }
  
  .slider-with-input input {
    width: 60px; /* Smaller input fields */
    padding: 5px; /* Padding inside input fields */
    font-size: 14px; /* Match font size with slider title */
    margin: 0; /* Remove any default margin */
  }


  .img-thumbnail {
    width: 50px; /* Smaller width */
    height: 50px; /* Smaller height */
    background-size: cover;
    margin: 0; /* Adjust margin for alignment */
    border: 2px solid transparent; /* Adjust for selection indication */
  }
  
  .img-thumbnail.selected {
    border-color: #000000; /* Highlight when selected */
  }

.card {
    background-color: #fff; /* White background */
    color: #333; /* Darker text */
    border: 1px solid #ddd; /* Light border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


.button,
.btn-success {
    background-color: #000000; /* Maroon background */
    color: #fff; /* White text */
    border-radius: 0.25rem; /* Rounded corners for a modern look */
}

.button:hover,
.btn-success:hover {
    background-color: #5a0000; /* Darker maroon on hover */
}

.alert-info {
    background-color: #260c1b; /* Alert background */
    color: #fff; /* Alert text */
    border: none; /* Remove border */
}
/* Adjustments for the sliders */
.slider-container .slider-with-input {
    display: flex;
    align-items: center;
}

/* Smaller checkboxes for color and clarity */
.color-clarity-checks .form-check {
    margin-right: 10px;
    width: 20%;
    display: inline-block; /* Show checkboxes inline */
}

/* Override Bootstrap navbar default styling */
.navbar {
    background-color: #fff !important; /* White background */
}

.navbar-brand,
.navbar-nav .nav-link {
    color: #000000 !important; /* Maroon text */
}

.navbar .nav-link.active {
    background-color: #f8f9fa;
    color: #000000 !important;
}
  
  /* Change dropdown menu item color */
  .navbar .dropdown-item {
    color: #fff; /* White text */
  }
  
  /* Change color for active or hovered nav items */
  .navbar .nav-item.active .nav-link,
  .navbar .nav-item:hover .nav-link {
    color: #ddd !important; /* Lighter shade of white for hover/active */
  }

.btn-success {
    background-color: #000000; /* Maroon background */
    color: white; /* White text */
    border: none; /* No border */
}

.btn-success:hover, .btn-success:active {
    background-color: #5a0000; /* Darker maroon for hover state */
    color: white; /* Ensure text remains white on hover */
}

.nav-tabs .nav-link.active {
    color: #000000; /* Maroon text */
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #000000; /* Maroon bottom border */
}
.img-thumbnail.selected {
    border: 2px solid #000000 !important; /* Maroon border for selected item */
}
.nav-tabs {
    justify-content: space-between;
}

.nav-tabs .nav-item {
    margin-right: 5px; /* Reduce spacing */
}

.nav-tabs .nav-link {
    color: #000000; /* Maroon text */
    background-color: transparent;
    border: none;
    margin-right: 2px;
}
  
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    box-shadow: inset 0 -2px 0 0 #495057;
  }

  .purchase-header {
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    background-position: center;
    padding: 20px;
    text-align: left;
}

.purchase-title {
    font-size: 28px; /* Adjusted font size */
    color: #333; /* Black text */
}

.purchase-description {
    font-size: 18px; /* Increased font size for better readability */
    color: #333; /* Black text */
    margin-top: 20px; /* Added some spacing */
}

.custom-inquiry-link {
    margin-left: 5px;
    color: #007bff; /* Bootstrap's default link color */
}

.reset-selection-btn {
    display: block;
    width: max-content;
    margin: 20px 0;
    background-color: #000000; /* Maroon background */
    color: #fff; /* White text */
}

.reset-selection-btn:hover {
    background-color: #5a0000; /* Darker maroon for hover state */
}

/* Adjusting general filter styles for a cohesive look */
.filter-row {
    margin-bottom: 20px;
  }
  
  .filter-section {
    background-color: #f4f4f4; /* Light background for clarity */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .filter-container {
    margin-bottom: 20px;
  }
  
  .filter-container h5 {
    font-size: 16px;
    margin-bottom: 15px; /* More space below header for visual separation */
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Increased gap for visual distinction */
  }
  
  /* Filter section adjustments for better visual organization */
.filter-row {
    margin-bottom: 20px;
  }
  
  .filter-section {
    background-color: #f4f4f4; /* Light background to emphasize filters */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  }
  
  .filter-container {
    margin-bottom: 20px; /* Space between filter options for clarity */
  }
  
  .filter-container h5 {
    font-size: 16px;
    font-weight: bold; /* Make titles bold */
    margin-bottom: 15px; /* More space below title for clear separation */
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Spacing between checkboxes for easier interaction */
  }
  
  
  /* Toggle (checkbox) customizations for cohesive theme appearance */
  .form-check-inline .form-check-input {
    appearance: none; /* Remove default browser styling */
    width: 1.25rem; /* Custom width */
    height: 1.25rem; /* Custom height */
    background-color: #f8f9fa; /* Light background for unchecked state */
    border: 2px solid #000000; /* Maroon border matching theme */
    border-radius: 0.25rem; /* Slightly rounded corners */
    cursor: pointer; /* Cursor changes to pointer to indicate interactivity */
  }
  
  .form-check-inline .form-check-input:checked {
    background-color: #000000; /* Maroon background for checked state */
    border-color: #000000; /* Consistent with the checked state background */
  }
  
  .form-check-inline .form-check-input:checked::before {
    content: ""; /* Using solid fill instead of checkmark */
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="%23ffffff"><rect width="8" height="8"/></svg>'); /* SVG for custom icon or shape */
    background-size: cover;
  }
  
  .form-check-label {     
    font-size: 14px; /* Reduced font size for labels */
    color: #333; /* Darker color for better readability */
    margin-left: 5px; /* Space between toggle and label */
  }

/* Slider Wrapper */
.slider-wrapper {
    padding: 0 15px; /* Adjusted padding for aesthetics */
    margin: 20px 0; /* Added margin to ensure separation from other elements */
  }
  
  /* Slider Rail, Track, and Handle Customizations */
  .rc-slider-rail {
    background-color: #ddd; /* Neutral rail background */
  }
  
  .rc-slider-track {
    background-color: #000000; /* Maroon track for visual consistency */
  }
  
  .rc-slider-handle {
    border: 1px solid #000000; /* Maroon handle border for visibility */
    background-color: #000; /* White handle for contrast */
    margin-top: -7px; /* Center handle vertically */
    margin-left: -7px; /* Center handle horizontally */
    width: 14px; /* Adjusted for a balanced size */
    height: 14px; /* Equal width and height for a circular shape */
    cursor: pointer;
  }
  
  /* Range Buttons Layout Adjustments */
  .range-buttons {
    width: auto !important;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px; /* Space from slider to buttons */
    justify-content: center; /* Center buttons if needed */
  }
  
  .range-buttons button {
    margin: 5px; /* Spacing around buttons */
    border: 1px solid #000000; /* Consistent with slider handle */
    background-color: #fff; /* Neutral background */
    color: #000000; /* Text color matches slider theme */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for interaction */
    display: inline-block;
  }
  
  .range-buttons button.active {
    background-color: #000000; /* Active state background */
    color: #fff; /* Active state text color */
  }

  /* Diamond Shape Buttons and Names Styling */
.shape-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
  }

  .shape-name {
    font-size: 10px; /* Smaller font size for shape names */
    margin-top: 5px; /* Space between image and name */
  }

  .price-and-cart-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .total-price-display {
    background-color: #f8f9fa;
    padding: 10px 15px;
    border-radius: 5px;
    color: #000000;
    font-weight: bold;
  }
  
  .add-to-cart-button {
    background-color: transparent;
    color: #000000;
    border: 2px solid #000000;
    padding: 5px 15px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
  }
  
  .add-to-cart-button:hover, .add-to-cart-button:focus {
    background-color: #000000;
    color: #fff;
    border-color: #000000;
  }
  
  /* Ensure the button fills in on click (active state) */
  .add-to-cart-button:active {
    background-color: #5a0000; /* A slightly darker maroon for a pressed effect */
    border-color: #5a0000;
  }

  /* Custom Card Styling */
.custom-card {
    border: 1px solid #ddd; /* Light border for a flat look */
    border-radius: 5px; /* Slightly rounded corners */
    background-color: #fff; /* White background */
    margin-bottom: 20px; /* Spacing between cards */
    box-shadow: none; /* Remove Bootstrap default shadow for a flat look */
    padding: 20px; /* Ample padding inside the card */
  }
  
  .custom-card-header {
    border-bottom: 1px solid #ddd; /* Light border for separation */
    padding-bottom: 10px; /* Smaller padding for the header */
    margin-bottom: 20px; /* Space between header and card content */
    font-weight: bold; /* Bold font for the header */
    font-size: 18px; /* Increased font size for readability */
    color: #000000; /* Matching the theme's color */
  }
  
  /* Aligning Filters and Shapes Tabs */
  .my-custom-tabs {
    display: flex;
    justify-content: center; /* Center the tabs */
    margin-bottom: 20px; /* Spacing between tabs and content */
  }
  
.inquiry-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .inquiry-description {
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
  }
  
  .custom-form-control {
    border-radius: 0; /* Hard edges */
    border: 1px solid #ccc; /* Light outline */
  }
  
  .custom-button {
    border-radius: 0; /* Hard edges for buttons */
    border: 2px solid; /* Solid border for a more defined look */
  }

  .email-input-container {
    text-align: center;
  }
  
  .email-input {
    width: 50%; /* Adjust width as needed */
    margin: 0 auto; /* Center the input field */
  }
  
  .submit-file-section,
  .submit-inquiry-section {
    text-align: center;
    margin-top: 20px; /* Add spacing between sections */
  }
  
  .submit-file-section .custom-button,
  .submit-inquiry-section .submit-inquiry-btn {
    margin-top: 10px; /* Spacing above the button */
  }
  
  /* Additional styling for form elements to ensure they are aesthetically pleasing */
  .custom-form-control {
    border-radius: 0; /* Hard edges */
    border: 1px solid #ccc; /* Light outline */
    /* Apply additional styling as needed */
  }
  
  /* Ensure the buttons have hard edges */
  .custom-button, .submit-inquiry-btn {
    border-radius: 0;
    /* Apply additional button styling as needed */
  }

  .slider-with-inputs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px; /* Added padding around the entire container */
    box-sizing: border-box; /* Makes sure padding doesn't increase the container's size */
    margin: 20px 0; /* Spacing above and below the container */
  }
  
  .slider-with-inputs .custom-slider {
    margin-bottom: 10px; /* Space between the slider and the input boxes */
  }
  
  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .input-group .min-input,
  .input-group .max-input {
    width: 50px; /* Smaller width for the input boxes */
    padding: 5px;
    margin: 0; /* No additional margin needed */
  }
  
  .input-group .unit {
    font-size: 0.75em;
    color: #777;
    margin-left: 5px; /* Small space between the input box and the unit label */
  }
  
  .custom-table-scroll {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: auto; /* Enable horizontal scrolling */
    border: 1px solid #ddd;
}

.custom-table thead th {
    position: sticky;
    top: 0;
    background-color: #000000;
    color: white;
    z-index: 10;
}

.custom-table {
    width: 100%;
    min-width: 800px;
    margin-bottom: 0;
    border-collapse: separate;
    border-spacing: 0;
}

.custom-table tbody td {
    padding: .5rem;
    text-align: center;
    vertical-align: middle;
    border-right: none;
}

.custom-table tbody tr:hover {
    background-color: #e8f0fe;
}

.custom-table tbody tr:nth-of-type(even) {
    background-color: #f8f9fa;
}

.custom-table .form-control,
.custom-table .form-check-input {
    margin: auto;
    display: block;
    width: 100%;
}

/* Additional styling */
.custom-table thead th {
    font-size: 0.9rem;
}

.custom-table td {
    font-size: 0.8rem;
    border-top: none;
    padding: 0.3rem;
}

.custom-table th, .custom-table td {
    border-right: none;
    border-left: none;
}

.custom-table th:last-child, .custom-table td:last-child {
    border-right: 1px solid #dee2e6;
}

.custom-table th.sticky-header {
    box-shadow: 0 2px 2px -1px rgba(0,0,0,0.4);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .custom-table-scroll {
        overflow-x: scroll;
    }

    .custom-table thead th,
    .custom-table tbody td {
        white-space: nowrap;
    }

    .custom-table {
        width: auto; /* Allow the table to be as wide as needed */
        min-width: 600px; /* Adjust as necessary */
    }

    .custom-table-scroll {
        max-height: 300px; /* Adjust height for mobile view */
    }
}

/* Additional mobile-friendly styles */
@media (max-width: 576px) {
    .custom-table-scroll {
        max-height: 200px;
    }
    
    .custom-table thead th,
    .custom-table tbody td {
        padding: 0.2rem;
        font-size: 0.7rem;
    }

    .custom-table th.sticky-header {
        font-size: 0.8rem;
    }
}